<template>
  <pmis-content-box :loading="listBoxLoading">
    <template v-slot:title>
      공사완료확인서
    </template>
    <template v-slot:title-left>
      <font style="color:#FF6666; font-weight: bold;">(더 많은 정보는 로그인 후 사용 바랍니다.)</font>
    </template>
    <template #title-right>
      <iui-button color="blue" v-show="isCanSign" @click="onSign">서명</iui-button>
    </template>
    <iui-container type="table" :theme="theme" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" id="iOzViewer">
            <iframe
              :src="urlParam"
              id="ozFrame"
              name="ozFrame"
              style="width:100%; height:100%; border:0px solid red;"
              scrolling="no"
              title="Iframe Example"
            ></iframe>
          </div>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
// eslint-disable-next-line
import {mapGetters} from 'vuex';
export default {
  name: 'confdoc-constcmpl-view',
  components: {},
  created() {
    this.addEvent({name: 'ConfDoc_ConstCmpl_Search', func: this.onSearch});
    console.log('addEvent', 'ConfDoc_ConstCmpl_Search');
    window.addEventListener('message', this.reqSetOzParam, false);
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      urlParam: '',
      OZParam: {},

      cnfrmnMain: {
        cnfrmnNo: '',
        cnfrmnDivCd: '',
        cmpnsDivNm: '',
        droperDivNm: '',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        cntrwkNo: '',
        cntrwkOdr: '',
        cnfrmnSttusCd: '',
        onlineYn: '',
        docmntSlngDe: '',
        fileNo: 0,
        rtrvlDt: '',
        sendngDt: '',
        tradeNo: '',
        tradeSfx: '',
      },

      signInfo: {
        signSn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        tradeSuffix: '',
        estmtNo: '',
        estmtOdr: 0,
        reqstdocNo: '',
        reqstdocOdr: 0,
        reqstdocDivCd: '',
        cnfrmnNo: '',
        signMthCd: '',
        signerSeCd: '',
        retrnResnCn: '',
        signDt: '',
        signoneCn: '',
        signtwoCn: '',
        signthreeCn: '',
        signfourCn: '',
        signfiveCn: '',
        agentIdntyImageCn: '',
        fileNo: 0,
        accesIp: '',
        accesEnvrnCn: '',
        pkcs7SignedMessage: '',
        signerDn: '',
        vidRandom: '',
        personId: '',
        businessNo: '',
        passNo: 0,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
        eliteAgreYn: '',
        viewAgreYn: '',
        infoProvdAgreYn: '',
        signClsf: '',
        signEmail: '',
        signName: '',
        signPsitn: '',
        signTelNo: '',
      },
    };
  },
  mounted() {},
  methods: {
    // ...mapMutations('OContractStore', {
    //   setContractInfo: 'setContractInfo'
    // }),

    async onSearch(isReload) {
      let param = {
        cnfrmnNo: this.decParams.cnfrmnNo,
      };
      let response = await axios.post('/urlLink/selectCnfrmnMainForConstCmpl', param);

      console.log('selectCnfrmnMainForConstCmpl=' + JSON.stringify(response));
      this.cnfrmnMain = response.data;

      this.signInfo.cnfrmnNo = this.decParams.cnfrmnNo;
      this.signInfo.tradeNo = this.decParams.tradeNo;
      this.signInfo.tradeSuffix = this.decParams.tradeSfx;
      this.signInfo.signMthCd = '30';
      this.signInfo.agentIdntyImageCn = '';

      if (this.cnfrmnMain.cnfrmnSttusCd == '20') {
        this.onViewReport(isReload, 'Y');
      } else {
        this.onViewReport(isReload, 'N');
      }
    },
    onViewReport(isReload, signable) {
      let param = {
        cnfrmnNo: this.decParams.cnfrmnNo,
      };

      axios
        .post('/ozrpt/selectOzCntrwkFileName', param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          this.OZParam.OZFormParam = {
            signable: signable, //필수(*):서명가능여부(Y/N)
            signMthCd: '30',
            cnfrmnNo: response.data.cnfrmnNo, //확인서번호
            cntrwkNo: response.data.cntrwkNo, //공사번호
            blinkCnfrmn: false, // 확인자 공란 여부
            userId: this.decParams.tradeNo,
          };

          this.urlParam = process.env.VUE_APP_OZ_QRY_VIEWER_URL;

          if (isReload) {
            //OZ Viewer 에서 message 를 호출할 Event 등록.
            window.addEventListener('message', this.reqSetOzParam, false);

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/ false || !!document.documentMode;

            if (isIE) {
              document.getElementById('ozFrame').src = process.env.VUE_APP_OZ_VIEWER_URL;
            } else {
              //OZ Reload
              document.getElementById('ozFrame').contentWindow.location.reload(true);
              // 서명패드가 계속 존재하는 상태로 있어서 사용 안함
              // document.getElementById('ozFrame').contentWindow.setOzReBindData( this.OZParam );
            }
          }
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.OZParam);

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.OZParam);

        //Event Listener 를 제거.
        window.removeEventListener('message', this.reqSetOzParam, false);
      }
    },
    async onSign() {
      //OZ Viewer 의 입력값들를 리턴받음.
      var ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
      console.log('onPopupSign input ', ozVal);
      if (ozVal == null || ozVal == '') {
        // this.$alert('디지털 전자서명 값이 존재하지 않습니다.');
        return;
      }

      this.signInfo.agentIdntyImageCn = ozVal.signData;

      if (this.signInfo.agentIdntyImageCn == undefined) {
        this.signInfo.agentIdntyImageCn = '';
        this.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
        return;
      }

      if (!this.signInfo.agentIdntyImageCn.trim()) {
        this.$alert('공사완료확인서의 서명란에 디지털서명을 해야합니다.');
        return;
      }

      this.signInfo.signClsf = ozVal.signClsf;
      this.signInfo.signEmail = ozVal.signEmail;
      this.signInfo.signName = ozVal.signName;
      this.signInfo.signPsitn = ozVal.signPsitn;
      this.signInfo.signTelNo = ozVal.signTelNo;

      this.signInfo.signerSeCd = '10'; // 본인 : 10, 대리인 : 20.

      let param = {
        ...this.signInfo,
        pgmCd: '201900',
        chngPgmId: 'ConstCmplView',
      };

      if (!(await this.$confirm('서명을 완료 하시겠습니까?'))) return;

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/urlLink/signCompleteForConstCmpl', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('서명이 완료되었습니다.');
          me.onSearch(true);
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('서명 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },

    error(err) {
      console.log(err);
    },
  },
  computed: {
    ...mapGetters('LinkConfDocStore', {
      decParams: 'decParams',
    }),

    isCanSign() {
      return this.cnfrmnMain.cnfrmnSttusCd == '20';
    },
  },
};
</script>

<style></style>
